import React from 'react'
import Start from '../../components/external/Start'


const ExternalAddAccount = () =>{


    return <Start></Start>
}

export default ExternalAddAccount