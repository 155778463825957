import React from 'react'
import { useLocation } from 'react-router-dom';

const Start = () => {
    const queryString = global.window ? global.window.location.search : '';
  
  // Parse the query string
    const queryParams = new URLSearchParams(queryString);
    const sessionId = queryParams.get('session');

    const redirectToService = async (redirectUri) => {
        window.location.href = redirectUri
      }
    return <div className="flex flex-col items-center justify-center h-screen">
    <div className="flex flex-col items-center justify-center gap-16">
        {sessionId}
        {sessionId && <>
        <div className="border rounded-full p-2 curosr-pointer" onClick={e=>{
            redirectToService(
                `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
                  process.env.GATSBY_OAUTH_GOOGLE_CLIENT
                }&redirect_uri=${
                  window.location.origin + process.env.GATSBY_OAUTH_GOOGLE_URL_BOOK
                }&response_type=code&prompt=login&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&access_type=offline&state=${
                  "external:" + sessionId // add other stuff?
                }`
              )
        }}>Add Google</div>
        <div className="border rounded-full p-2 cursor-pointer" onClick={e=>{
            redirectToService(
                `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=login&client_id=${
                  process.env.GATSBY_OAUTH_O365_CLIENT
                }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read&state=${
                    "external:" +sessionId // add other stuff?
                }&redirect_uri=${
                  window.location.origin + process.env.GATSBY_OAUTH_O365_URL_BOOK
                }`
              )
        }}>Add MS</div>
        </>}
    </div>
    
    </div>
}

export default Start 